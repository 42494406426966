import { EMarketName } from '../../../ui-config/marketsConfig';
import camelotPoolAbi from './camelotPoolAbi.json';
import quickswapPoolAbi from './quickswapPoolAbi.json';

export const ABI_BY_MARKET_NAME = {
  [EMarketName.None]: '',
  [EMarketName.Uniswap]: '',
  [EMarketName.Camelot]: camelotPoolAbi,
  [EMarketName.Quickswap]: quickswapPoolAbi,
}
