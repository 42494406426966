import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
// import { store } from '../../state/store';

const httpLink = new HttpLink({ uri: '' })

// const authMiddleware = new ApolloLink((operation, forward) => {
//   const chainId = store.getState().application.chainId;
//   console.log('operation', operation);
//   console.log('forward', forward);
//   operation.setContext((...params: any) => {
//     console.log('params', params);
//     return {
//       uri: chainId && CHAIN_SUBGRAPH_URL[chainId]
//         ? CHAIN_SUBGRAPH_URL[chainId][EMarketName.Uniswap]
//         : '',
//     };
//   });
//
//   return forward(operation);
// })

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink, //concat(authMiddleware, httpLink),
});
