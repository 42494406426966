import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Currency, Price, Token } from '@uniswap/sdk-core';
import { FeeAmount } from '@uniswap/v3-sdk';
import { SxProps, Theme } from '@mui/material';
import { saturate } from 'polished';
import { ReactNode, useCallback, useMemo } from 'react';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CloudOffRoundedIcon from '@mui/icons-material/CloudOffRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import styled from 'styled-components';

import { IPositionPoolData } from '../../types/pools';
import { Chart } from './Chart';
import { Bound, ZoomLevels } from './types';
import { useDensityChartData } from './hooks/useDensityChartData';

const ZOOM_LEVELS: Record<FeeAmount, ZoomLevels> = {
  [FeeAmount.LOWEST]: {
    initialMin: 0.999,
    initialMax: 1.001,
    min: 0.00001,
    max: 1.5,
  },
  [FeeAmount.LOW]: {
    initialMin: 0.999,
    initialMax: 1.001,
    min: 0.00001,
    max: 1.5,
  },
  [FeeAmount.MEDIUM]: {
    initialMin: 0.5,
    initialMax: 2,
    min: 0.00001,
    max: 20,
  },
  [FeeAmount.HIGH]: {
    initialMin: 0.5,
    initialMax: 2,
    min: 0.00001,
    max: 20,
  },
}

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 200px;
  justify-content: center;
  align-content: center;
`

function InfoBox({ message, icon }: { message?: ReactNode; icon: ReactNode }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, pt: 12 }}>
      {icon}
      {message && (
        <Typography>
          {message}
        </Typography>
      )}
    </Box>
  )
}

export const LiquidityChartRangeInput = ({
  positionPoolData,
  currencyA,
  currencyB,
  ticksAtLimit,
  price,
  priceLower,
  priceUpper,
  onLeftRangeInput,
  onRightRangeInput,
  interactive: interactiveOuter,
  sx,
}: {
  positionPoolData: IPositionPoolData;
  currencyA?: Currency;
  currencyB?: Currency;
  ticksAtLimit: { [bound in Bound]?: boolean | undefined };
  price?: number;
  priceLower?: Price<Token, Token>;
  priceUpper?: Price<Token, Token>;
  onLeftRangeInput: (typedValue: string) => void;
  onRightRangeInput: (typedValue: string) => void;
  interactive: boolean;
  sx?: SxProps<Theme>;
}) => {
  const { breakpoints } = useTheme();
  const md = useMediaQuery(breakpoints.up('md'));
  const isSorted = currencyA && currencyB && currencyA?.wrapped.sortsBefore(currencyB?.wrapped);
  const { isLoading, error, formattedData } = useDensityChartData({ positionPoolData, currencyA, currencyB });

  const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined) => {
      let leftRangeValue = Number(domain[0])
      const rightRangeValue = Number(domain[1])

      if (leftRangeValue <= 0) {
        leftRangeValue = 1 / 10 ** 6
      }

      // simulate user input for auto-formatting and other validations
      if (
        (!ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER] || mode === 'handle' || mode === 'reset') &&
        leftRangeValue > 0
      ) {
        onLeftRangeInput(leftRangeValue.toFixed(6))
      }

      if ((!ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER] || mode === 'reset') && rightRangeValue > 0) {
        // todo: remove this check. Upper bound for large numbers
        // sometimes fails to parse to tick.
        if (rightRangeValue < 1e35) {
          onRightRangeInput(rightRangeValue.toFixed(6))
        }
      }
    },
    [isSorted, onLeftRangeInput, onRightRangeInput, ticksAtLimit]
  );

  const interactive = interactiveOuter && Boolean(formattedData?.length);

  const brushDomain: [number, number] | undefined = useMemo(() => {
    const leftPrice = isSorted ? priceLower : priceUpper?.invert()
    const rightPrice = isSorted ? priceUpper : priceLower?.invert()

    return leftPrice && rightPrice
      ? [parseFloat(leftPrice?.toSignificant(6)), parseFloat(rightPrice?.toSignificant(6))]
      : undefined
  }, [isSorted, priceLower, priceUpper]);

  // const formatDelta = (value: number) => value;

  // const brushLabelValue = useCallback(
  //   (d: 'w' | 'e', x: number) => {
  //     if (!price) return ''
  //
  //     if (d === 'w' && ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER]) return '0'
  //     if (d === 'e' && ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER]) return '∞'
  //
  //     const percent = (x < price ? -1 : 1) * ((Math.max(x, price) - Math.min(x, price)) / price) * 100
  //
  //     return price ? `${(Math.sign(percent) < 0 ? '-' : '') + formatDelta(percent)}` : ''
  //   },
  //   [formatDelta, isSorted, price, ticksAtLimit]
  // )

  const isUninitialized = !currencyA || !currencyB || (formattedData === undefined && !isLoading);

  return (
    <Box sx={{ minHeight: md ? '200px' : '120px', ...sx }}>
      {isUninitialized ? (
        <InfoBox
          message="Your position will appear here."
          icon={<InboxRoundedIcon />}
        />
      ) : isLoading ? (
        <InfoBox icon={<CircularProgress />} />
      ) : error ? (
        <InfoBox
          message="Liquidity data not available."
          icon={<CloudOffRoundedIcon />}
        />
      ) : !formattedData || formattedData.length === 0 || !price ? (
        <InfoBox
          message="There is no liquidity data."
          icon={<BarChartRoundedIcon />}
        />
      ) : (
        <ChartWrapper>
          <Chart
            data={{ series: formattedData, current: price }}
            dimensions={{ width: 560, height: 176 }}
            margins={{ top: 10, right: 2, bottom: 20, left: 0 }}
            styles={{
              area: {
                selection: '#ff0000',
              },
              brush: {
                handle: {
                  west: saturate(0.1, '#8061E5'),
                  east: saturate(0.1, '#8061E5'),
                },
              },
            }}
            interactive={interactive}
            // brushLabels={brushLabelValue}
            brushDomain={brushDomain}
            onBrushDomainChange={onBrushDomainChangeEnded}
            zoomLevels={ZOOM_LEVELS[positionPoolData.feeAmount || FeeAmount.MEDIUM]}
            ticksAtLimit={ticksAtLimit}
          />
        </ChartWrapper>
      )}
    </Box>
  )
}
