import { BigNumber as EthBigNumber } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useQuery } from '@tanstack/react-query';

import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { checkMarketFeesExists } from '../../utils/checkMarketFeesExists';
import { EMarketName } from '../../ui-config/marketsConfig';
import { useRootStore } from '../../store/root';
import { ABI_BY_MARKET_NAME } from './abi/constants';

export interface IPoolGlobalStateResponse {
  communityFeeToken0: number;
  communityFeeToken1: number;
  feeOtz: number;
  feeZto: number;
  price: EthBigNumber;
  tick: number;
  timepointIndex: number;
  unlocked: boolean;
}

const INITIAL_DATA = {} as IPoolGlobalStateResponse;

export const usePoolGlobalStateQuery = (poolAddress: string, marketName: EMarketName = EMarketName.None) => {
  const provider = useRootStore((store) => store.jsonRpcProvider())
  const marketWithFees = checkMarketFeesExists(marketName);
  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.POOL_GLOBAL_STATE, marketName, poolAddress],
    queryFn: () => {
      const contract = new Contract(poolAddress, ABI_BY_MARKET_NAME[marketName], provider);
      return contract.functions.globalState() as unknown as IPoolGlobalStateResponse;
    },
    enabled: Boolean(poolAddress && marketName) && !marketWithFees,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
};
