import { Contract } from '@ethersproject/contracts'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import { useMemo } from 'react'
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { getContract } from '../utils/getContract';
import { MULTICALL_ADDRESSES } from '@uniswap/sdk-core';
import { ChainId } from '@yldr/contract-helpers';
import { UniswapInterfaceMulticall } from '@uniswap/redux-multicall/dist/abi/types';

const { abi: MulticallABI } = UniswapInterfaceMulticallJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, chainId } = useWeb3Context()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible]) as T
}

export function useInterfaceMulticall() {
  return useContract(MULTICALL_ADDRESSES, MulticallABI, false);
}

function useMainnetContract<T extends Contract = Contract>(address: string | undefined, ABI: any): T | null {
  const { chainId, provider } = useWeb3Context();
  const isMainnet = chainId === ChainId.mainnet;
  const contract = useContract(isMainnet ? address : undefined, ABI, false)

  return useMemo(() => {
    if (isMainnet) return contract;
    if (!address || !provider) return null;
    try {
      return getContract(address, ABI, provider);
    } catch (error) {
      console.error('Failed to get mainnet contract', error);
      return null;
    }
  }, [isMainnet, contract, address, ABI]) as T
}

export function useMainnetInterfaceMulticall() {
  return useMainnetContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESSES[ChainId.mainnet],
    MulticallABI
  ) as UniswapInterfaceMulticall
}
