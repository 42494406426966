import JSBI from 'jsbi';
import ms from 'ms';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Currency } from '@uniswap/sdk-core';
import { FeeAmount, tickToPrice } from '@uniswap/v3-sdk';

import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { CHAIN_SUBGRAPH_URL } from '../../../ui-config/networksConfig';
import { EMarketName } from '../../../ui-config/marketsConfig';
import { IPositionPoolData } from '../../../types/pools';
import { apolloClient } from '../graphql/thegraph/apollo';
import { TickData, Ticks } from '../graphql/thegraph/AllV3TicksQuery';
import { useAllV3TicksQuery } from '../graphql/thegraph/__generated__/types-and-hooks';
import computeSurroundingTicks from '../utils/computeSurroundingTicks';

const PRICE_FIXED_DIGITS = 8;

// Tick with fields parsed to JSBIs, and active liquidity computed.
export interface TickProcessed {
  tick: number
  liquidityActive: JSBI
  liquidityNet: JSBI
  price0: string
}

function useTicksFromSubgraph(
  { positionPoolData, skip = 0, enabled = true }: {
    positionPoolData: IPositionPoolData;
    skip: number;
    enabled: boolean;
  }
) {

  const { chainId } = useWeb3Context();

  const graphUri = CHAIN_SUBGRAPH_URL[chainId][positionPoolData.marketName || EMarketName.None];

  return useAllV3TicksQuery({
    variables: { poolAddress: positionPoolData.address?.toLowerCase(), skip },
    skip: !positionPoolData.address || !enabled || !graphUri,
    pollInterval: ms(`30s`),
    client: apolloClient,
    context: { uri: graphUri },
  });
}

const MAX_THE_GRAPH_TICK_FETCH_VALUE = 1000;
// // Fetches all ticks for a given pool
function useAllV3Ticks(
  { positionPoolData }: { positionPoolData: IPositionPoolData }
): {
  isLoading: boolean
  error: unknown
  ticks?: TickData[]
} {
  const [skipNumber, setSkipNumber] = useState(0);
  const [subgraphTickData, setSubgraphTickData] = useState<Ticks>([]);
  const feeAmountRef = useRef<FeeAmount>();

  const enabled = feeAmountRef.current === positionPoolData.feeAmount;

  const { data, error, loading: isLoading } = useTicksFromSubgraph({ positionPoolData, skip: skipNumber, enabled });

  useEffect(() => {
    if (feeAmountRef.current && feeAmountRef.current !== positionPoolData.feeAmount) {
      setSkipNumber(0);
      setSubgraphTickData([]);
      feeAmountRef.current = positionPoolData.feeAmount;
      return;
    } else {
      feeAmountRef.current = positionPoolData.feeAmount;
    }
    if (data?.ticks.length) {
      setSubgraphTickData((tickData) => [...tickData, ...data.ticks])
      if (data.ticks.length === MAX_THE_GRAPH_TICK_FETCH_VALUE) {
        setSkipNumber((skipNumber) => skipNumber + MAX_THE_GRAPH_TICK_FETCH_VALUE)
      }
    }
  }, [data?.ticks, positionPoolData.feeAmount]);

  return {
    isLoading: isLoading || data?.ticks.length === MAX_THE_GRAPH_TICK_FETCH_VALUE,
    error,
    ticks: subgraphTickData,
  }
}

export function usePoolActiveLiquidity(
  { positionPoolData, currencyA, currencyB }: {
    positionPoolData: IPositionPoolData;
    currencyA?: Currency;
    currencyB?: Currency;
  }
): {
  isLoading: boolean
  error: any
  data?: TickProcessed[]
} {
  const { isLoading, error, ticks } = useAllV3Ticks({ positionPoolData });

  return useMemo(() => {
    if (
      !currencyA ||
      !currencyB ||
      !positionPoolData.liquidity ||
      !ticks ||
      positionPoolData.activeTick === undefined ||
      ticks.length === 0 ||
      isLoading
    ) {
      return {
        isLoading,
        error,
        data: undefined,
      }
    }

    const token0 = currencyA?.wrapped;
    const token1 = currencyB?.wrapped;

    // find where the active tick would be to partition the array
    // if the active tick is initialized, the pivot will be an element
    // if not, take the previous tick as pivot
    const pivot = ticks.findIndex(({ tick }) => tick > positionPoolData.activeTick) - 1;

    if (pivot < 0) {
      // consider setting a local error
      console.error('TickData pivot not found');
      return {
        isLoading,
        error,
        data: undefined,
      };
    }

    const activeTickProcessed: TickProcessed = {
      liquidityActive: JSBI.BigInt(positionPoolData.liquidity ?? 0),
      tick: positionPoolData.activeTick,
      liquidityNet: Number(ticks[pivot].tick) === positionPoolData.activeTick ? JSBI.BigInt(ticks[pivot].liquidityNet) : JSBI.BigInt(0),
      price0: tickToPrice(token0, token1, positionPoolData.activeTick).toFixed(PRICE_FIXED_DIGITS),
    };

    const subsequentTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, true);

    const previousTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, false);

    const ticksProcessed = previousTicks.concat(activeTickProcessed).concat(subsequentTicks);

    return {
      isLoading,
      error,
      data: ticksProcessed,
    };
  }, [currencyA, currencyB, positionPoolData.activeTick, positionPoolData.liquidity, ticks, isLoading, error])
}
