import { Contract } from '@ethersproject/contracts';
import { useQuery } from '@tanstack/react-query';

import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { checkMarketFeesExists } from '../../utils/checkMarketFeesExists';
import { EMarketName } from '../../ui-config/marketsConfig';
import { useRootStore } from '../../store/root';
import { ABI_BY_MARKET_NAME } from './abi/constants';

export const usePoolTickSpacingQuery = (poolAddress: string, marketName: EMarketName = EMarketName.None) => {
  const provider = useRootStore((store) => store.jsonRpcProvider())
  const marketWithFees = checkMarketFeesExists(marketName);
  return useQuery({
    queryKey: [QueryKeys.POOL_TICK_SPACING, marketName, poolAddress],
    queryFn: () => {
      const contract = new Contract(poolAddress, ABI_BY_MARKET_NAME[marketName], provider);
      return contract.functions.tickSpacing() as unknown as number[];
    },
    enabled: Boolean(poolAddress && marketName) && !marketWithFees,
    refetchInterval: POLLING_INTERVAL,
  });
};
