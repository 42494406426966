import BigNumber from 'bignumber.js';
import { FeeAmount, TICK_SPACINGS } from '@uniswap/v3-sdk';

export const getActiveTick = ({
  tickCurrent,
  feeAmount,
}: {
  tickCurrent?: number;
  feeAmount?: FeeAmount;
}) => {
  return tickCurrent && feeAmount
    ? BigNumber(tickCurrent)
      .div(TICK_SPACINGS[feeAmount])
      .integerValue(BigNumber.ROUND_FLOOR)
      .multipliedBy(TICK_SPACINGS[feeAmount])
      .toNumber()
    : undefined;
};
